<template>
    <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
        <h1>Apple Music New Release</h1>
        <div id="applemusicnewrelease">

            <div v-if="!data.length" class="text-center">
            {{ loading }}
          </div>

        </div>


    </main>
</template>
<script>
export default {
  name: "AppleMusicNewRelease",
  data() {
    return {
      loading: "Please wait...",
      data: [],
      addLoading: -1
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
            this.$axios
        .get(`${this.$api}/applemusicnewrelease`)
        .then(res => {
          console.log(res);
          document.getElementById("applemusicnewrelease").innerHTML = res.data.data;
        });
    },
  
    showResponder: function(responder){
                // No need to prevent any more
                this.activeResponder = responder;
            },
  }
};
</script>
<style>
.image-list-small {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  padding: 0;
}

.image-list-small li {
  display: inline-block;
  width: 18%;
  margin: 0 12px 30px;
}

@media only screen and (max-width: 600px) {
  .image-list-small li {
  width: 44%;
  }
}
@media only screen and (min-width: 992px) {
  .image-list-small li {
  width: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .image-list-small li {
  width: 45%;
  }
}

@media only screen and (min-width: 1200px) {
  .image-list-small li {
  width: 15%;
  }
}

/* Photo */

.image-list-small li > a {
  display: block;
  text-decoration: none;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  margin: 0;
  padding: 0;
  border: 4px solid #ffffff;
  outline: 1px solid #d0d0d0;
  box-shadow: 0 2px 1px #DDD;
}

.image-list-small .details {
  margin-top: 13px;
}


/* Title */

.image-list-small .details h3 {
  display: block;
  font-size: 12px;
  margin: 0 0 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-list-small .details h3 a {
  color: #303030;
  text-decoration: none;
}

.image-list-small .details .image-author {
  display: block;
  color: #717171;
  font-size: 11px;
  font-weight: normal;
  margin: 0;
}
h1 {
    margin: 1%;
    text-align: center;
    font-size: x-large;
    color: cornflowerblue;
    width: 100%;
}

</style>